import { useState, useEffect } from "react";


function Joke(props) {

    const [categories, setCategories] = useState([]);
    const [selected, setSelected] = useState("");
    const [joke, setJoke] = useState("");

    useEffect(() => {
        console.log("this ran");
        fetch("https://api.chucknorris.io/jokes/categories")
            .then((res) => res.json())
            .then((categories) => setCategories(categories))
            .catch((err) => console.log(err));

        setJoke(localStorage.getItem("current-joke"));
        setSelected(localStorage.getItem("category"));
    }, []);

    const fetchJoke = async (category) => {
        let response = await fetch(
            "https://api.chucknorris.io/jokes/random?category=" + category
        );
        let jokeData = await response.json();

        console.log(jokeData);
        console.log(jokeData.value);

        setJoke(jokeData.value);
        localStorage.setItem("current-joke", jokeData.value);
    };

    const handleLogout = (e) => {
        e.preventDefault()
        console.log('Logout');
        sessionStorage.clear();
        props.history.push('/');

    }

    return (
        <div className='container'>

            <h3 className='center-align'>Chuck Norris Jokes</h3>

            <p className='center-align'>
            <a class="waves-effect waves-red btn-flat" onClick={handleLogout}>Logout</a>
            </p>

            <div className="row">
                <div className="col s12 m7 l6">
                    <p className="center-align">Categories</p>
                    {categories.length ? (
                        categories.map((category) => (
                            <div key={category} className="col s3 mb-3 center-align">
                                <button
                                    onClick={() => {
                                        setSelected(category);
                                        localStorage.setItem("category", category);
                                        fetchJoke(localStorage.getItem("category") || selected);
                                        console.log(`You Selected ${category}`);
                                    }}
                                    className={
                                        category === selected
                                            ? "btn-transform waves-effect btn-flat blue white-text"
                                            : "btn-transform waves-effect btn-flat"
                                    }
                                >
                                    {category}
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
                <div className="col s12 m5 l6">
                    <p className="center-align">
                        {localStorage.getItem("category")
                            ? `Selected Category: ${selected}`
                            : "Select a category"}
                    </p>
                    <p className="center-align blue p-joke">{joke ? joke : "Loading"}</p>
                    <div className="center-align">
                        <button
                            disabled={joke === null}
                            onClick={() => {
                                fetchJoke(localStorage.getItem("category") || selected);
                            }}
                            className="btn-transform waves-effect btn-flat"
                        >
                            {" "}
        New Joke{" "}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Joke
